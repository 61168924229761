<template>
  <el-dialog
    width="30%"
    title="Adicionar produto à licitação"
    append-to-body
    v-model="shouldShow"
  >
    <h4 class="mb-0">Produto:</h4>
    <el-select
      filterable
      remote
      v-model="productOrder.product"
      @change="selectProduct"
      :loading="!products"
      value-key="uid"
      loading-text="Buscando produtos..."
      :remote-method="searchProducts"
      size="medium"
    >
      <el-option
        v-for="item in Products"
        :disabled="!item.is_active"
        :key="item.uid"
        :label="item?.product?.name"
        :value="item"
      >
      </el-option>
    </el-select>

    <h4 class="mb-0">Preço por Kg:</h4>
    <base-input v-model="productOrder.amount" type="money">
      <template #append v-if="productOrder.product?.price">
        Ref:
        {{
          currencyFormatter.format(
            Number(productOrder.product?.price?.female_std)
          )
        }}
      </template>
      <template
        #prefix
        v-if="productOrder.amount && productOrder.product?.price"
      >
        <el-icon
          size="small"
          class="el-icon-arrow-down"
          color="red"
          style="line-height: 36px"
          v-if="
            Number(productOrder.amount) <
            Number(productOrder.product?.price?.female_std)
          "
        ></el-icon>
        <el-icon
          size="small"
          class="el-icon-arrow-up"
          color="green"
          style="line-height: 36px"
          v-else
        ></el-icon>
      </template>
    </base-input>

    <h4 class="mb-0">Peso Máximo:</h4>
    <base-input
      v-model="productOrder.total_weight"
      type="money"
      :minimumFractionDigits="3"
    ></base-input>

    <h4 class="mb-0">Forma de armazenamento:</h4>
    <el-select v-model="productOrder.storage" size="medium">
      <el-option
        v-for="item in StorageProperties"
        :key="item"
        :label="item.name"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <h4 class="mb-0">Obs:</h4>
    <base-input
      v-model="productOrder.obs"
      :minimumFractionDigits="3"
      validationRules="required"
      @validation="validationResult['obs'] = $event"
      type="textarea"
    >
    </base-input>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="addProductOrder"
          >Adicionar produto</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import ProductService from "../../services/products";
import { ElNotification } from "element-plus";
import { currencyFormatter } from "../../utils/formatters";
export default {
  name: "ProductPublicSell",
  props: ["showModal"],
  components: { BaseInput },
  emits: ["onAddProduct", "closeModal"],
  computed: {
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        return this.$emit("close-modal");
      },
    },
    Products() {
      return this.mountedProducts() || [];
    },
    StorageProperties() {
      return [
        { name: "Resfriado", value: "cold" },
        { name: "Congelado", value: "freezed" },
      ];
    },
  },
  data: () => ({
    productOrder: {},
    products: null,
    validationResult: {},
    currencyFormatter,
  }),
  methods: {
    mountedProducts() {
      return (
        this.products
          ?.map((p) =>
            p?.mounted
              .map((m) => ({
                ...p,
                ...(m?.codes || {}),
                is_public_sell_available: m.is_public_sell_available,
                uid: m.id,
                price: m?.price,
                productUid: p.uid,
                product: {
                  name: `${p.name} ${m.cutting ?? ""} ${m.composition ?? ""} ${
                    m.storage ?? ""
                  }`,
                },
              }))
              ?.flat()
              ?.filter(({is_public_sell_available}) => is_public_sell_available)
          )
          ?.flat() || []
      );
    },
    selectProduct({ productUid, uid }) {
      this.productOrder.product_id = productUid;
      this.productOrder.product_mounted_id = uid;
    },
    async searchProducts(name) {
      const { products } = await ProductService().index({ searchName: name, is_public_sell_available: true });

      if (products) {
        this.products = products;
      }
    },
    hasEmptyInputs() {
      return !this.productOrder.product;
    },
    addProductOrder() {
      if (this.hasEmptyInputs())
        return ElNotification.error({
          title: "Não é possível adicionar este produto",
          message:
            "Informe corretamente um produto, peso e preço antes de adicionar",
        });
      this.$emit("on-add-product", this.productOrder);
      this.productOrder = {};
      this.$emit("close-modal");
    },
  },
};
</script>
